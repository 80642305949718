import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import Layout from '../components/layout';

import StickyButtons from '../components/StickyButtons/StickyButtons';
import FeaturedProperties from '../components/FeaturedProperties/FeaturedProperties';

const Banner = loadable(() => import("../components/Banner/Banner"));
const StatsModule = loadable(() => import("../components/StatsModule/StatsModule"));
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"));
const SimilarProperties = loadable(() => import("../components/SimilarProperties/SimilarProperties"));
const ReviewsModule = loadable(() => import("../components/ReviewsModule/ReviewsModule"));
const CtaModule = loadable(() => import("../components/CtaModule/CtaModule"));
const NewsEvents = loadable(() => import("../components/NewsEvents/NewsEvents"));
const NewsLetterModule = loadable(() => import("../components/NewsLetterModule/NewsLetterModule"));
const TeamSlider = loadable(() => import("../components/TeamSlider/TeamSlider"));

const LandingPage = ({ data }) => {
    const PageData = data?.strapiPage

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })

    }, [])

    return (
        <Layout footerCta="footercta" popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>

            {PageData.banner &&
                <Banner
                    {...PageData.banner}
                    title={PageData.banner?.banner_title}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                />
            }

            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.image-and-content" &&
                            <TileBlock {...module} tag="landing" />
                        }
                        {module.strapi_component === "page-modules.stats-module" &&
                            <StatsModule tag="pt-0" {...module} />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "cta_module" &&
                            <CtaModule />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_reviews_slider" && renderComponent &&
                            <ReviewsModule {...module} />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties_sales" && renderComponent &&
                            <FeaturedProperties {...module} tag="landing"/>
                        }
                        {module.strapi_component === "page-modules.team-slider" &&
                            <TeamSlider {...module} bgColor="layout-dark-bg-theme" />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "latest_news_slider" &&
                            <NewsEvents {...module} />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "newsletter_module" &&
                            <NewsLetterModule {...module} />
                        }
                    </>
                )
            })}
            <StickyButtons tag="landing-page" />
        </Layout>
    )
}

export default LandingPage

export const query = graphql`
    query ($page_id: String) {
        strapiPage(id: {eq: $page_id}) {
            ...PageFragment
            Add_Page_Modules {
                ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                    ...GlobalModuleFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
                    ...ImageAndContentFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                    ...PlainContentFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_STATS_MODULE {
                    ...StatsModuleFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_TEAM_SLIDER {
                    ...TeamSliderModuleFragment
                }
            }
        }
    }
`