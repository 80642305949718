import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { Link } from "gatsby";
import loadable from "@loadable/component";
import ScrollAnimation from 'react-animate-on-scroll';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderSettings } from "../SliderSettings/SliderSettings";
import PropertyCardSlide from "../PropertyCardSlide/PropertyCardSlide";
import { ApiRequest } from "../../common/utils/api_request_utils";
import './assets/styles/_index.scss';
import { PageLinks } from "../../common/site/page-static-links";

const Slider = loadable(() => import("react-slick"));

const FeaturedProperties = (props) => {
    const [propItems, setPropItems] = useState([])
    const [letpropItems, setLetPropItems] = useState([])
    const [activeTab, setActiveTab] = useState('property-for-sale')

    useEffect(() => {
        ApiRequest({
            method: "GET",
            url: `${process.env.GATSBY_STRAPI_SRC}/api/stb-lists/item/Featured_Properties`
        }, (result) => {
            if (result.length > 0) {
                setPropItems(result)
            }
        })

        ApiRequest({
            method: "GET",
            url: `${process.env.GATSBY_STRAPI_SRC}/api/stb-lists/item/Featured_Properties_Lettings`
        }, (result) => {
            if (result.length > 0) {
                setLetPropItems(result)
            }
        })
    }, []);

    let view_all_url = PageLinks.results_sales

    if (activeTab === "property-to-rent") {
        view_all_url = PageLinks.results_lettings
    }

    return (
        <section className={`featured-properties-wrapper section-p-120 ${props.tag}`}>
            <Container>
                {props?.short_title &&
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn="animate__slideInUp" delay={300} animateOnce offset={10}>
                                <div className="primary-text-line">{props?.short_title}</div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col lg={6} md={8}>
                        <ScrollAnimation animateIn="animate__slideInUp" delay={600} animateOnce offset={10}>
                            <h2>{props.title}</h2>
                        </ScrollAnimation>
                    </Col>
                    <Col lg={6} md={4} className="d-md-flex justify-content-end d-none">
                        <ScrollAnimation animateIn="animate__slideInUp" delay={600} animateOnce offset={10}>
                            <Link to={`/${view_all_url}/`} className="button button-primary-outline-transparent">View All</Link>
                        </ScrollAnimation>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ScrollAnimation animateIn="animate__slideInUp" delay={600} animateOnce offset={10}>
                            <Tabs
                                defaultActiveKey="property-for-sale"
                                className="property-slider-tabs"
                                onSelect={(e) => setActiveTab(e)}
                            > {propItems.length > 0 &&
                                <Tab eventKey="property-for-sale" title="Property for Sale">
                                    <Slider className="property-slider" {...sliderSettings}>
                                        {propItems.map((property, i) => {

                                            let details_path = '/property-for-sale'
                                            let processedImages = JSON.stringify({});


                                            if (property?.imagetransforms?.images_Transforms) {
                                                processedImages = property?.imagetransforms?.images_Transforms;
                                            }
                                            var image_all = JSON.parse(property.images.replace('\"', '"'))
                                            return (
                                                <PropertyCardSlide
                                                    {...property}
                                                    details_path={details_path}
                                                    img={image_all}
                                                    processedImages={processedImages}
                                                />
                                            )
                                        })
                                        }
                                    </Slider>
                                    <div className="d-md-none property-slider-btn">
                                        <Link to={`/${view_all_url}/`} className="button button-primary-outline-transparent">View All</Link>
                                    </div>
                                </Tab>
                                }
                                <Tab eventKey="property-to-rent" title="Property to Rent">
                                    <Slider className="property-slider" {...sliderSettings}>
                                        {letpropItems.map((property, i) => {

                                            let details_path = '/property-to-rent'
                                            let processedImages = JSON.stringify({});

                                            if (property?.imagetransforms?.images_Transforms) {
                                                processedImages = property?.imagetransforms?.images_Transforms;
                                            }
                                            var image_all = JSON.parse(property.images.replace('\"', '"'))

                                            return (
                                                <PropertyCardSlide
                                                    {...property}
                                                    details_path={details_path}
                                                    img={image_all}
                                                    processedImages={processedImages}
                                                />
                                            )
                                        })
                                        }
                                    </Slider>
                                    <div className="d-md-none property-slider-btn">
                                        <Link to={`/${view_all_url}/`} className="button button-primary-outline-transparent">View All</Link>
                                    </div>
                                </Tab>
                            </Tabs>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FeaturedProperties